import React, { Fragment, useState, useContext } from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import { LogoSolid } from 'src/components/Logo'
import { AppContext } from 'src/state/AppState'
import Grid from 'src/components/Grid'
import ScrollListener from 'src/components/ScrollListener'
import Button from 'src/components/Button'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import RestaurantsPanel from 'src/components/RestaurantsPanel'
import { colors, typography, animations, mq, util } from 'src/styles'
import { FaInstagram } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'

const showHide = false // show and hide header on scroll
const headerHeight = (additionalHeight = 0) => util.responsiveStyles('height', (120 + additionalHeight), (100 + additionalHeight), (100 + additionalHeight), (75 + additionalHeight))
export const headerHeightCollapsed = (additionalHeight = 0, attr = 'height') => util.responsiveStyles(attr, (70 + additionalHeight), (70 + additionalHeight), (66 + additionalHeight), (60 + additionalHeight))

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  // min-width: 200px;
  background: ${ colors.textColor };
  color: ${ colors.lightGrey };
  ${ typography.bodySmall };
  font-weight: 600;
  letter-spacing: 0;
  padding: 10px 16px;
  visibility: hidden;
  opacity: 0;
  transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
  z-index: 100;
  > div {
    width: 100%;
    white-space: nowrap;
    padding-right: 1em;
  }
  a {
    display: block;
    padding: 3px 0;
    opacity: .6;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 5px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${ colors.mainColor };
      top: 50%;
      margin-top: -3px;
      opacity: 0;
      transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
    }
    &:hover {
      opacity: 1;
      &:after {
        opacity: 1;
        transform: rotate(-45deg) translate3d(5px, 5px, 0);
      }
    }
  }
`

const NavLinkStyle = (scrolled, active, hasAtf, dropdown) => `
  display: block;
  position: relative;
  ${ typography.h6 }
  line-height: 1em;
  cursor: pointer;
  padding: .5em 0;
  transition:   padding ${ animations.mediumSpeed } ease-in-out,
                margin ${ animations.mediumSpeed } ease-in-out,
                background ${ animations.mediumSpeed } ease-in-out,
                color ${ animations.mediumSpeed } ease-in-out;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0px;
    right: 0;
    height: 2px;
    background: ${ colors.mainColor };
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
    ${ hasAtf && !scrolled ? `
      background: ${ colors.lightGrey };
    ` : `` }
  }
  &:hover {
    &:after {
      transform-origin: left center;
      transform: scaleX(1);
    }
  }
  ${ active ? `
    &:after {
      transform-origin: left center;
      transform: scaleX(1);
    }
  ` : `` }
`

const NavLink = styled(Link)`
  ${ props => NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
  ${ ({ hasAtf }) => hasAtf ? `
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  ` : `
    background: ${ colors.offWhite };
  ` }
  ${ ({ mobileMenuOpen }) => mobileMenuOpen ? `
    ${ mq.largeAndBelow } {
      background: transparent;
    }
  ` : `` }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: height ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              box-shadow ${ animations.mediumSpeed } ease-in-out;
  ${ ({ scrolled, hasAtf, mobileMenuOpen }) => scrolled ? `
		${ headerHeightCollapsed() }
		background: ${ colors.offWhite };
		color: ${ colors.textColor };
    ${ mq.largeAndBelow } {
      ${ mobileMenuOpen ? `
        background: transparent;
        color: ${ colors.offWhite };
        box-shadow: none;
        ${ headerHeight() }
      ` : `` }
    }
	` : `
		${ headerHeight() }
		background: transparent;
		${ hasAtf ? `
			color: ${ colors.lightGrey };
		` : `
			color: ${ colors.textColor };
		` }
    ${ mobileMenuOpen ? `
      background: transparent;
      color: ${ colors.offWhite };
      box-shadow: none;
      ${ headerHeight() }
    ` : `` }
	` }
  ${ ({ navVisible }) => navVisible && `
		transform: translate3d(0, -101%, 0);
	` }
`

const HeaderContent = styled(Grid)`
  height: 100%;
  > div {
    height: 100%;
  }
`

const HeaderLogo = styled(LogoSolid)`
	${ util.responsiveStyles('width', 180, 180, 160, 140) }
  ${ util.responsiveStyles('margin-left', -13, -11, -9, -7) }
	height: auto;
  color: inherit !important;
	transition: color ${ animations.mediumSpeed } ease-in-out,
              width ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, hasAtf, mobileMenuOpen }) => scrolled ? `
		${ util.responsiveStyles('width', 160, 160, 140, 120) }
    ${ mq.mediumAndBelow } {
      ${ mobileMenuOpen ? `
        ${ util.responsiveStyles('width', 250, 225, 180, 140) }
      ` : `` }
    }
	` : `
  ${ mobileMenuOpen ? `
    ${ util.responsiveStyles('width', 250, 225, 180, 140) }
  ` : `` }
	` }
`

const LogoCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
	a {
		display: inline-block;
		vertical-align: top;
    transition: none;
    max-width: 100%;
	}
`

const NavLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: ${ ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start' };
  li {
    position: relative;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      ${ Dropdown } {
        visibility: visible;
        opacity: 1;
      }
    }
    &:not(:last-of-type) {
      ${ util.responsiveStyles('margin-right', 40, 30, 20, 20) }
    }
  }
`

const MenuIcon = styled.div`
	display: none;
  padding: 5px 10px;
  margin-right: -10px;
  cursor: pointer;
  display: block;
  vertical-align: top;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  span {
    display: block;
  }
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height ${ animations.mediumSpeed } ease-in-out;
	${ ({ hasBanner }) => hasBanner ? `
		${ headerHeight(36) }
	` : `
		${ headerHeight() }
	` }
  // margin-bottom: ${ 100 / 14 }vw;
  ${ mq.largeAndUp } {
    // margin-bottom: ${ 100 / 26 }vw;
  }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
`

const MobileFooterCTA = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding-left: ${ 100 / 14.5 }vw;
  padding-right: ${ 100 / 14.5 }vw;
  padding-bottom: ${ 100 / 14.5 }vw;
  transition: transform ${ animations.mediumSpeed } ease-in-out;
  ${ ({ mobileMenuOpen }) => mobileMenuOpen ? `
    transform: translate3d(0, 150%, 0);
  ` : `` }
  .button, button {
    width: 100%;
    display: block;
  }
`

const CtaButton = styled(Button)`
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: 100%;
  padding-left: 3em;
  padding-right: 3em;
  margin-right: ${ 100 / -26 }vw;
  ${ mq.extraLargeAndUp } {
    margin-right: ${ 100 / -40 }vw;
  }
`

const SocialIconLink = styled(Link)`
  display: block;
  padding: 4px;
  margin: 0 -4px 0 -8px;
  ${ mq.largeAndUp } {
    margin: -4px -4px 0;
  }
  &:hover {
    opacity: .6;    
  }
  svg {
    display: block;
  }
`

const Header = ({
  location,
  hasAtf = false,
  bannerText,
  collapsed,
  bannerColor,
  navigation,
  socialLinks,
  title,
  restaurants,
  pageType = 'page',
  firstSection,
  site
}) => {
  const [ mobileMenuOpen, toggleMobileMenu ] = useState(false)
  const [ bannerVisible, toggleBanner ] = useState(true)
  const { modal, toggleModal } = useContext(AppContext)

  const toggleRestaurantsList = id => {
    toggleModal(id)
  }

  const restaurantList = modal === 'restaurantList'

  let pathname = location
  let pageHasAtf = hasAtf && !mobileMenuOpen

  return (
    <Fragment>
      <ScrollListener.Consumer>
        {({
          scrolledToTop,
          scrolledToBottom,
          scrollY,
          scrolledUp,
          hasScrolled,
          pageHeight
        }) => {
          let scrolled = false
          if (collapsed) {
            scrolled = true
          } else {
            scrolled = !scrolledToTop && hasScrolled
          }

          const instagramLink = socialLinks?.filter(link => link?.to?.includes('instagram'))[0]
          return (
            <Wrapper hasAtf={pageHasAtf} navVisible={!scrolledUp && !scrolledToTop && showHide} mobileMenuOpen={mobileMenuOpen}>
              {bannerText && (
                <HeaderNotificationBanner
                  closeBanner={() => toggleBanner(false)}
                  collapsed={!bannerVisible}
                  text={bannerText}
                  setTheme={location === '/' || !location ? 'mainColor' : bannerColor}
                />
              )}
              <HeaderWrapper
                navVisible={!scrolledUp && !scrolledToTop && showHide}
                hasAtf={pageHasAtf}
                scrolled={scrolled}
                mobileMenuOpen={mobileMenuOpen}
                navVisible={!scrolledUp && !scrolledToTop && showHide}
              >
                <HeaderContent
                  small="1 [4] [4] [4] 1"
                  medium="1 [4] [4] [4] 1"
                  large="1 [8] [8] [8] 1"
                  larger="1 [14] [10] [14] 1"
                  vAlign="center"
                  hasAtf={pageHasAtf}
                  navVisible={!scrolledUp && !scrolledToTop && showHide}
                >
                  <div>
                    <NavLinks alignment="left">
                      <ResponsiveComponent
                        small={
                          <>
                            {site.streetAddress && (
                              <li>
                                <SocialIconLink
                                  scrolled={scrolled}
                                  hasAtf={pageHasAtf}
                                  to={site.directionsLink}
                                  external={true}
                                  target={'_blank'}
                                  title='Directions to The Hugh'
                                >
                                  <MdLocationOn size={22} />
                                </SocialIconLink>
                              </li>
                            )}
                          </>
                        }
                        medium={
                          <>
                            {site.streetAddress && (
                              <li>
                                <NavLink
                                  scrolled={scrolled}
                                  hasAtf={pageHasAtf}
                                  to={site.directionsLink}
                                  external={true}
                                  target={'_blank'}
                                >
                                  {site.streetAddress.replace(/ Street/g, '')}
                                </NavLink>
                              </li>
                            )}
                          </>
                        }
                        custom={{
                          breakpoint: 1023,
                          content: <>
                            {navigation && navigation.map((link, index) => {
                              let linkTo = '/'
                              if (link.to) {
                                linkTo = link?.to?.slug !== '/' ? ('/' + link?.to?.slug) : '/'
                              }
                              if (link.externalLink) {
                                linkTo = link.externalLink
                              }
                              return (
                                <li key={'header-link-' + link.id}>
                                  <NavLink
                                    scrolled={scrolled}
                                    hasAtf={pageHasAtf}
                                    to={linkTo}
                                    active={pathname === link?.to?.slug}
                                    key={link?.to?.slug}
                                    hasDropdown={link.dropdownLinks}
                                    external={link.externalLink}
                                    target={link.externalLink && '_blank'}
                                  >
                                    {link.label}
                                  </NavLink>
                                  {link.dropdownLinks && (
                                    <Dropdown>
                                      {link.dropdownLinks.map((dropdownLink, index) => {
                                        let dropdownLinkTo = '/'
                                        if (dropdownLink.to) {
                                          dropdownLinkTo = dropdownLink?.to?.slug !== '/' ? ('/' + dropdownLink?.to?.slug) : '/'
                                        }
                                        if (dropdownLink.externalLink) {
                                          dropdownLinkTo = dropdownLink.externalLink
                                        }
                                        return (
                                          <div>
                                            <Link
                                              to={dropdownLinkTo}
                                              external={dropdownLink.externalLink}
                                              target={dropdownLink.externalLink && '_blank'}
                                            >{dropdownLink.label}</Link>
                                          </div>
                                        )
                                      })}
                                    </Dropdown>
                                  )}
                                </li>
                              )
                            })}
                          </>
                        }}
                      />
                    </NavLinks>

                  </div>
                  <LogoCol>
                    <Link to="/" title={title}>
                      <HeaderLogo
                        scrolled={scrolled}
                        type={scrolled || mobileMenuOpen ? 'solid' : 'default'}
                        hasAtf={pageHasAtf}
                        mobileMenuOpen={mobileMenuOpen}
                      />
                    </Link>
                  </LogoCol>
                  <div>
                    <NavLinks alignment="right">
                      <ResponsiveComponent
                        small={
                          <>
                            <li>
                              <MenuIcon id="mobile-menu-icon" onClick={() => toggleMobileMenu(!mobileMenuOpen)}>
                                <AnimatedIcon
                                  icon={mobileMenuOpen ? 'close' : 'menu'}
                                />
                              </MenuIcon>
                            </li>
                          </>
                        }
                        medium={
                          <>
                            <li>
                              <MenuIcon id="mobile-menu-icon" onClick={() => toggleMobileMenu(!mobileMenuOpen)}>
                                <AnimatedIcon
                                  icon={mobileMenuOpen ? 'close' : 'menu'}
                                />
                              </MenuIcon>
                            </li>
                          </>
                        }
                        custom={{
                          breakpoint: 1023,
                          content: <>
                            <li>
                              <NavLink
                                scrolled={scrolled}
                                hasAtf={pageHasAtf}
                                to={site.directionsLink}
                                external={true}
                                target={'_blank'}
                              >
                                {site.streetAddress.replace(/ Street/g, '')}
                              </NavLink>
                            </li>
                            <li>
                              <CtaButton setTheme={mobileMenuOpen ? 'lightGrey' : 'tan'} size='medium' onClick={() => toggleRestaurantsList('restaurantList')}>Order Online</CtaButton>
                            </li>
                          </>
                        }}
                      />
                    </NavLinks>

                  </div>
                </HeaderContent>
              </HeaderWrapper>
            </Wrapper>
          )
        }}
      </ScrollListener.Consumer>

      {!hasAtf && (
        <HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
      )}

      <ResponsiveComponent
        small={
          <MobileMenu
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            navLinks={navigation}
            socialLinks={socialLinks}
            toggleRestaurantsList={toggleRestaurantsList}
            site={site}
          />
        }
        larger={<span />}
      />

      <RestaurantsPanel
        open={restaurantList}
        toggleRestaurantsList={toggleRestaurantsList}
        restaurants={restaurants}
      />

      <ResponsiveComponent
        small={
          <MobileFooterCTA mobileMenuOpen={mobileMenuOpen}>
            <Button size='large' setTheme={mobileMenuOpen ? 'lightGrey' : 'default'} onClick={() => toggleRestaurantsList('restaurantList')}>Order Online</Button>
          </MobileFooterCTA>
        }
        medium={
          <span/>
        }
      />

    </Fragment>
  )
}

export default Header
